import React from 'react';

const About = () => {
  return (
    <div id="about-me" class="about-me">
      <h2 class="name">
        <span class="long-dash"></span>
        <span class="name-text">About me</span>
      </h2>
      <p>
      I started my development journey exploring different languages before finding my way to Ruby on Rails, where I built a discussion platform that connects users across Africa.
      </p>
      <p>
      Using Rails and JavaScript, I've implemented features from content aggregation to real-time messaging, focusing on performance and reliability. I enjoy solving complex problems and building efficient systems, always looking to learn and implement new technologies to improve user experience.
      </p>
    </div>
  )
}

export default About;